<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      :class="{ show: mqShallShowLeftSidebar }"
      class="body-content-overlay"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">
          {{ $t('Start Conversation') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                :src="activeChat.contact.profilePhoto"
                size="36"
                class="mr-1 badge-minimal"
              />

              <h6 class="mb-0">
                {{ activeChat.contact.firstName }}
                {{ activeChat.contact.lastName }}
              </h6>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="userData.participant.profilePhoto"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              :placeholder="$t('Enter your message')"
            />
          </b-input-group>
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="$refs.pdfFile.click()"
          >
            <input
              ref="pdfFile"
              type="file"
              accept="application/pdf"
              class="d-none"
              @input="uploadPdf($refs.pdfFile)"
            />
            <feather-icon icon="PaperclipIcon" size="15" />
          </b-button>
          <b-button variant="primary" type="submit">
            {{ $t('Send') }}
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.id : null
        "
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, nextTick, computed } from '@vue/composition-api'
import { BAvatar, BForm, BInputGroup, BFormInput, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatLog from './ChatLog.vue'

export default {
  components: {
    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatLog,
  },
  setup() {
    // ------------------------------------------------
    // Message refresh rate
    // ------------------------------------------------
    const CONTACTS_FETCH_RATE = 1 * 60 * 1000 // fetch all new contacts and chats
    const MESSAGE_FETCH_RATE = 10 * 1000 // fetch chat NEW messages
    let GLOBAL_PING = null
    let CHAT_PING = null

    // UnRegister on leave
    onUnmounted(() => {
      clearInterval(GLOBAL_PING)
      clearTimeout(CHAT_PING)
    })

    const userData = computed(() => store.getters['app-common/userData'])

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])

    const fetchChatAndContacts = () => {
      store.dispatch('app-chat/fetchChatsAndContacts').then((response) => {
        chatsContacts.value = response.data
          .map((c) => ({
            ...c,
            unreadMsgsCnt: parseInt(c.unreadMsgsCnt, 10),
          }))
          .filter((c) => c.message)

          contacts.value = response.data

        })
    }

    fetchChatAndContacts()

    GLOBAL_PING = setInterval(() => {
      fetchChatAndContacts()
    }, CONTACTS_FETCH_RATE)

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')

    const openChatOfContact = (userId) => {
      // Reset send message input value
      clearTimeout(CHAT_PING)
      chatInputMessage.value = ''

      const activeContact = contacts.value.find((c) => c.id === userId)

      store.dispatch('app-chat/getAllChatData', { userId }).then((response) => {
        activeChat.value = response

        // Set unreadMsgsCnt to 0
        if (activeContact) {
          activeContact.unreadMsgsCnt = 0
        }
        activeChat.value.contact = { ...activeContact }
        //to reflect the unreadMsgsCnt value immediately - disappear notification from inner chat section
        fetchChatAndContacts()

      })

      // const refreshChat = () => {
      //   store.dispatch('app-chat/getNewMessages', { userId })
      //     .then(response => {
      //       const l = response.length
      //       if (l > 0) {
      //         activeChat.value.chats.concat(response)
      //         activeContact.message = activeChat.value.chats[l - 1].message

      //         // Scroll to bottom
      //         nextTick(() => { scrollToBottomInChatLog() })
      //       }

      //       CHAT_PING = setTimeout(() => {
      //         refreshChat(userId)
      //       }, MESSAGE_FETCH_RATE)
      //     })
      // }

      // CHAT_PING = setTimeout(() => {
      //   refreshChat(userId)
      // }, MESSAGE_FETCH_RATE)

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    const uploadPdf = (input) => {
      const file = input && input.files && input.files[0]
      if (file && file.type == 'application/pdf') {
        const formData = new FormData()
        formData.append('file', file)
        store.dispatch('app-chat/uploadPdf', formData).then((response) => {
          const fileDetails = {
            file: response.data.file,
            type: 'MEDIA',
          }
          chatInputMessage.value = response.data.field
          sendMessage(fileDetails)
        })
      }
    }

    const sendMessage = (file) => {
      if (!chatInputMessage.value) {
        return
      }
      const payload = {
        contactId: activeChat.value.contact.id,
        message: chatInputMessage.value,
        ...(file && file),
      }
      store.dispatch('app-chat/sendMessage', payload).then((response) => {
        const newMessageData = response.data
        activeChat.value.chats.push(newMessageData)

        // Reset send message input value
        chatInputMessage.value = ''

        // Set Last Message for active contact
        const contact = contacts.value.find(
          (c) => c.id === activeChat.value.contact.id
        )
        contact.message = newMessageData.message

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      uploadPdf,

      // UI
      perfectScrollbarSettings,

      userData,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
