<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      :src="user.profilePhoto"
      size="42"
      class="badge-minimal"
      variant="transparent"
    />

    <div class="chat-info d-flex flex-column justify-content-center">
      <h5 class="mb-0">
        {{ user.firstName }} {{ user.lastName }}
      </h5>
      <p
        v-if="isChatContact"
        class="card-text text-truncate"
      >
        {{ user.message }}
      </p>
    </div>

    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap text-right"
    >
      <small class="d-block mb-25 chat-time">
        {{ formatDateToMonthShort(user.creationDate, { hour: 'numeric', minute: 'numeric' }) }}
      </small>

      <b-badge
        v-if="user.unreadMsgsCnt"
        pill
        variant="primary"
      >
        {{ user.unreadMsgsCnt }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { formatDateToMonthShort }
  },
}
</script>
