<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      :class="{ 'chat-left': msgGrp.senderId === formattedChatData.contact.id }"
      class="chat"
    >
      <div class="chat-avatar">
        <b-avatar
          :src="
            msgGrp.senderId === formattedChatData.contact.id
              ? formattedChatData.contact.avatar
              : profileUserAvatar
          "
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time"
          class="chat-content"
        >
          <p v-if="msgData.type == 'TEXT'">{{ msgData.msg }}</p>
          <a
            v-if="msgData.type == 'MEDIA'"
            :href="msgData.file"
            class="chat-pdf"
            target="_blank"
            ><u>{{ msgData.msg }}</u>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.profilePhoto,
      }

      let chatLog = []
      if (props.chatData.chats) {
        chatLog = props.chatData.chats
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      }
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            type: msg.type,
            file: msg.file,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [
              {
                msg: msg.message,
                time: msg.time,
                type: msg.type,
                file: msg.file,
              },
            ],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>
