<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Sidebar Content -->
      <div
        :class="{'show': mqShallShowLeftSidebar}"
        class="sidebar-content"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                :src="userData.participant.profilePhoto"
                size="42"
                variant="light-primary"
                class="badge-minimal"
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                :placeholder="$t('Search') + '...'"
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <h4 class="chat-list-title">
            {{ $t('Chats') }}
          </h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <template v-if="filteredChatsContacts.length">
              <chat-contact
                v-for="contact in filteredChatsContacts"
                :key="contact.id"
                :user="contact"
                :class="{'active': activeChatContactId === contact.id}"
                tag="li"
                is-chat-contact
                @click="$emit('open-chat', contact.id) "
              />
            </template>

            <template v-else>
              <li><i>{{ $t('No chats found...') }}</i></li>
            </template>
          </ul>

          <!-- Contacts Title -->
          <h4 class="chat-list-title">
            {{ $t('Contacts') }}
          </h4>

          <!-- Contacts -->
          <ul class="chat-users-list contact-list media-list">
            <template v-if="filteredContacts.length">
              <chat-contact
                v-for="contact in filteredContacts"
                :key="contact.id"
                :user="contact"
                tag="li"
                @click="$emit('open-chat', contact.id)"
              />
            </template>

            <template v-else>
              <li><i>{{ $t('No contacts found...') }}</i></li>
            </template>
          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import ChatContact from './ChatContact.vue'

export default {
  components: {

    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    userData() {
      return this.$store.getters['app-common/userData']
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = contact => {
      const fullName = `${contact.firstName} ${contact.lastName}`.toLowerCase()
      return fullName.includes(searchQuery.value.toLowerCase())
    }

    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))
    const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
